import { createTheme  } from "@material-ui/core/styles";
import { blueGrey, lightBlue } from "@material-ui/core/colors";

export const theme = createTheme ({
    palette: {
      primary: { main:'#6D1A12', light:'#a14738', dark:'#3f0000'},  //#2196f3 //4466f2
      secondary: { main: '#BD8B40', light:'#f2bb6e', dark:'#895e12' }, //#1A237E //0847a7
      tertiary:{main:'#96AE76'},
      layout:{background:'#F1F1F2'},
      text:{
        primary:blueGrey[900],
        secondary:blueGrey[500],
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:[
        'Raleway',
        'Montserrat',
        'Work Sans',
      ].join(','),
    },
    overrides:{
      MuiTypography:{
        h1:{
          fontSize:'4.5rem',
          fontWeight:500
        },
        h2:{
          fontSize:'3.75rem',
        },
        h3:{
          fontSize:'3rem',
        },
        h4:{
          fontWeight:600,
          fontSize:'2.250rem',
        },
        h5:{
            fontSize:'1.875rem',
            fontWeight:600
        },
        h6:{
          fontSize:'1.5rem',
          fontWeight:600,
        },
        subtitle1:{
          fontSize:'1.250rem',
          fontWeight:600,
        },
        subtitle2:{
          fontSize:'1.125rem',
          fontWeight:600,
        },
        body1:{
            fontSize:'1.125rem',
            fontWeight:500,
        },
        body2:{
            fontSize:'1rem',
            fontWeight:500,
        },
        caption:{
          fontSize:'0.875rem',
          fontWeight:500,
        },
        button:{
          fontSize:'1.125rem'
        },
      }
    }
});