import axios from 'axios'
export const nodata = '-'

export const CONFIG_SETTINGS = {
    MODE: 'local',
    API_USERNAME: process.env.REACT_APP_API_USERNAME,
    API_PASSWORD: process.env.REACT_APP_API_PASSWORD,
    API_BASIC: `Basic ${btoa(process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD)}`,
    S3_SERVER_URL: 'https://s3.us-east-1.amazonaws.com/galenox-dev-public/',
    DASH_USERNAME: process.env.REACT_APP_DASH_USERNAME,
    DASH_PASSWORD: process.env.REACT_APP_PASSWORD,
    API_STRIPE_KEY: "pk_test_Vi6IkrV0vmi2i5o3hLYlw1rJ00zMj5MJhk"
}

export const DASHBOARD_SETTINGS = {
    BOXSHADOW: '0px 12px 18px rgba(0, 0, 0, 0.1)',
    BOXSHADOW_PRIMARY: '0px 6px 14px rgba(202, 203, 251, 0.87)',
    DIVIDER_LINE: '1px solid #B3B3B3',
    COUNTRY_CODE: 1,
    CURRENCY_ID: 2,
    TOPBAR: {
        TRANSPARENT_MODE: 'TRANSPARENT_MODE',
        WHITE_MODE: 'WHITE_MODE',
        HEIGHT: 72
    },
    PROFILE_DRAWER: {
        WIDTH: 350,
        MWIDTH: 200,
        SWIDTH: 260
    }
}

export const TOKEN_STATUSES = {
    VALID: 1,
    EXPIRED: 2,
    REAUTH: 3,
    NOT_VALID: -1
}

export const CHECKOUT_STEPS = {
    SELECT_DELIVERY_METHOD: 1,
    WAREHOUSE_DELIVERY: 2,
    WAREHOUSE_PICKUP: 3,
    ADDRESS_DELIVERY_SCHEDULE: 4,
    ADDRESS_DELIVERY: 5,
    ADDRESS_DELIVERY_MAP: 6,
    PAYMENT_METHODS: 7,
    OVERVIEW: 10,
    COMPLETED: 11

}

export const private_server = axios.create({
    baseURL: "http://184.73.25.123/dev",
    headers: {
        'Content-Type': 'application/json',
    },
})

export const public_server = axios.create({
    baseURL: "http://184.73.25.123/dev",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': CONFIG_SETTINGS.API_BASIC
    }
})

export const LAYOUT_SETTINGS = {
    BOXSHADOW: '0 10px 24px 0 rgba(82, 91, 115, .12)'
}
