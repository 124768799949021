export const actionTypes = {
    ARROW_TRANSLATE: 'ARROW_TRANSLATE',
    ////////////////////////////////////////////////////////
    AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
    AUTH_UPDATE_STATUS: 'AUTH_UPDATE_STATUS' ,
    SYS_UPDATE_PROFILE_PICTURE: 'SYS_UPDATE_PROFILE_PICTURE',
    SYS_UPDATE_DRAWER_STATUS: 'SYS_UPDATE_DRAWER_STATUS',
    SYS_UPDATE_DRAWER: 'SYS_UPDATE_DRAWER',
    SYS_UPDATE_BLUR: 'SYS_UPDATE_BLUR',
    SYS_UPDATE_TOKEN:'SYS_UPDATE_TOKEN',
    SYS_UPDATE_SESSION_STATUS:'SYS_UPDATE_SESSION_STATUS',
    SYS_UPDATE_ERROR:'SYS_UPDATE_ERROR',
    SYS_UPDATE_MODAL:'SYS_UPDATE_MODAL',
    SYS_UPDATE_LANGUAGE:'SYS_UPDATE_LANGUAGE',
    ///////////////////////// MODALS //////////////////
    MODAL_UPDATE_PROFILE_PICTURE:'MODAL_UPDATE_PROFILE_PICTURE',
    ///////////////////////// DRAWERS /////////////////////
    DRAWER_UPDATE_LOGIN: 'DRAWER_UPDATE_LOGIN',
    DRAWER_UPDATE_SIGNUP: 'DRAWER_UPDATE_SIGNUP'
}