import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user: null,
    profile_picture: null,
    is_auth: false,
    token: null,
    language: 'en',
    cart: null,
    ip_address: null,
    drawer_status: true,
    blur: false,
    arrow_translate: false,
    /////////// Drawers /////////////
    login_drawer: false,
    signup_drawer: false,
    register: false,
    profile_picture_modal: false,
    // Error Handler
    error: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        ///////////////////// PAGE ///////////////////////////////
        case actionTypes.ARROW_TRANSLATE:
            return updateObject(state, { arrow_translate: action.arrow_translate })
        ///////////////////// SYS  ///////////////////////////////
        case actionTypes.SYS_UPDATE_LANGUAGE:
            return updateObject(state, { language: action.language })
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, { user: action.user })
        case actionTypes.AUTH_UPDATE_STATUS:
            return updateObject(state, { is_auth: action.is_auth })
        case actionTypes.SYS_UPDATE_PROFILE_PICTURE:
            return updateObject(state, { profile_picture: action.profile_picture })
        case actionTypes.SYS_UPDATE_IP_ADDRESS:
            return updateObject(state, { ip_address: action.ip_address })
        case actionTypes.SYS_UPDATE_DRAWER_STATUS:
            return updateObject(state, { drawer_status: action.drawer_status })
        case actionTypes.SYS_UPDATE_BLUR:
            return updateObject(state, { blur: action.blur })
        case actionTypes.SYS_UPDATE_TOKEN:
            return updateObject(state, { token: action.token })
        case actionTypes.SYS_UPDATE_SESSION_STATUS:
            return updateObject(state, { session_status: action.session_status })
        //////////////////////   DRAWERS  //////////////////////
        case actionTypes.DRAWER_UPDATE_LOGIN:
            return updateObject(state, { login_drawer: action.login_drawer })
        case actionTypes.DRAWER_UPDATE_SIGNUP:
            return updateObject(state, { signup_drawer: action.signup_drawer })
        //////////////////////   MODALS   //////////////////////
        case actionTypes.MODAL_UPDATE_PROFILE_PICTURE:
            return updateObject(state, { profile_picture_modal: action.profile_picture_modal })
        case actionTypes.SYS_UPDATE_MODAL:
            return updateObject(state, { modals: { ...state.modals, ...action.modal } })
        //////////////////////   ERROR HANDLER   //////////////////////
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, { error: action.error })
        default:
            return state;

    }
}

export const store = createStore(reducer)

