import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import TemporalyUnavailableLayout from '../layouts/TemporalyUnavailableLayout/TemporalyUnavailableLayout';

const MainRouter = ({ history }) => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={'/'} component={TemporalyUnavailableLayout} />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default MainRouter