import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Divider, Grid, Icon, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import LanguageButton from '../../../components/Actions/LanguageButton'
import StopIcon from '@material-ui/icons/Stop'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
        position:'relative',
        [theme.breakpoints.only('xs')]:{
            height:'100vh',
        }
    },
    imageBanner: {
        position:'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition:'left',
        //filter: 'brightness(50%)',
        [theme.breakpoints.only('xs')]:{
            objectFit:'contain',
            objectPosition:'center',
            objectFit: 'cover',
        }
    },
    content: {
        width: '100%',
        height: '100%',
        color: 'white',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        textAlign: 'center',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    topTitle: {
        fontSize: 28,
        fontWeight: 28,
        paddingBottom: 12
    },
    title: {
        fontSize: 48,
        fontWeight: 400,
        paddingBottom: 12
    },
    date: {
        fontSize: 32,
        fontWeight: 400
    },
    arrow: {
        visibility: 'hidden',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(100px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
    carousel: {
        width: '100%',
        background: 'black'
    },
    language: {
        transform: 'translate(-100px,90vh)'
    },
}))

const MainBanner = ({ content }) => {

    const classes = styles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [language, setLanguage] = useState('en')

    const banner = isMobile ? content.bannerMobile : content.banner

    return (
        <div className={classes.root}>
            <img src={banner} className={classes.imageBanner} />
        </div >
    )
}

export default MainBanner