import React, { useEffect } from 'react';
import { translations as translations_esp } from '../../texts/esp/home-translations'
import MainBanner from './sections/MainBanner';
import { makeStyles } from '@material-ui/styles';


const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
}))

const HomeView = ({ history }) => {

    const classes = styles()

    const content = translations_esp

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <MainBanner content={content.main_banner_section} />
    )
}

export default HomeView