import { useState, useEffect, useRef } from "react"
import { TOKEN_STATUSES } from "../../config"
import { onClearTokenSession, onGetCurrentUserProfile, onGetTokenFromMemory, onReAuthToken, onValidateToken } from "../../shared/utility"

const useTemporalyUnavailableLayout = ({ onUpdateUserData, onUpdateAuthStatus, onUpdateArrowPosition }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useInterval(() => {
        //actions.onCheckTokenExpirationDate()
    }, 3000)

    const actions = {
        onInitModule: async () => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                let _user, _cart
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                    default:
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                }


            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onCheckTokenExpirationDate: async () => {
            if (busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                    case TOKEN_STATUSES.NOT_VALID:
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }
            } catch (error) {
                console.log(error)
            }
        },
        onUpdateNavArrow: (inView, entry) => {
            onUpdateArrowPosition(inView)
        }
    }

    return { loading, sending, error, actions }
}

export default useTemporalyUnavailableLayout;

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
