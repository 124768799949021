import React, { useEffect, useState, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { makeStyles, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
//import Topbar from './components/Topbar';
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon';
import cx from 'classnames'
//import Footer from './components/Footer';
import useTemporalyUnavailableLayout from './useTemporalyUnavailableLayout';
import ErrorModal from '../../components/Modals/ErrorModal'
//import LogInDrawer from '../LogInDrawer/LogInDrawer'
//import SignUpDrawer from '../SignUpDrawer/SignUpDrawer'
import general_translations from '../../texts/esp/general-translations'

const TemporalyUnavailableLayout = ({ history, language, blur, user, is_auth, error, onUpdateArrowPosition,
    onUpdateUserData, onUpdateAuthStatus, onUpdateLanguage, onUpdateError }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const content = general_translations.arrow_navigate

    const { loading, sending, isVisible, actions } = useTemporalyUnavailableLayout({ onUpdateArrowPosition, onUpdateUserData, onUpdateAuthStatus })

    useInterval(() => {
        actions.onCheckTokenExpirationDate()
    }, 3000)

    return (
        <div className={cx({
            [classes.root]: true,
            [classes.root_blur]: blur
        })}>
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
                onClose={() => onUpdateError(null)} />
            {/*<SignUpDrawer />
            <LogInDrawer />*/}
            <main className={classes.main} >
                {/*<Topbar history={history} ismobile={ismobile} />*/}
                <WebsiteRouter />
                {/*<Footer language={language} history={history} onUpdateLanguage={onUpdateLanguage}/>*/}
            </main>

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        //background:'#edf2f8',
        minHeight: '100vh',
        overflowY: 'auto',
        filter: 'none'

    },
    root_blur: {
        filter: 'blur(3px)'
    },
    main: {
        //marginLeft:150,
        //padding:16,
        overflowY: 'auto'

    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
    arrowUp: {
        transform: 'translate(40px,40vh)',
    }
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        ip_address: state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error,
        arrowTranslate: state.arrow_translate,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateLanguage: (language) => dispatch({ type: actionTypes.SYS_UPDATE_LANGUAGE, language }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateError: (error) => dispatch({ type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemporalyUnavailableLayout);

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}